import React from 'react';
import './About.scss';
import Navigation from '../../components/Navigation/Navigation';

function About() {
  return (
    <div className="About Page">
      <Navigation />
      <div className="content">
        <h1>About Us</h1>
        <img
          src="/logos/Doodle-Bug-Authors.jpg"
          alt="Doodle Bug Authors"
          className="authors"
        />
        <p>
          Meet the Doodle Bug: Enrique Florendo and Marisa Oge are a creative
          husband-and-wife team inspired by their two young children.
        </p>
        <p>
          Marisa is an talented illustrator with a background in art, bringing
          vibrant characters to life with her beautiful illustrations.
        </p>
        <p>Enrique weaves enchanting stories that captivate young readers.</p>
        <p>
          Together, they create magical tales filled with imagination, kindness,
          and valuable life lessons.
        </p>
        <p>
          When not creating, they enjoy family adventures and exploring nature,
          making memories that fuel their storytelling.
        </p>
        <p>
          Join them on their journey as they share heartwarming stories for
          children to cherish.
        </p>
      </div>
    </div>
  );
}

export default About;
