export const data = [
  {
    id: 'charley-the-horse-with-a-unicorn-wish',
    title: 'Charley: The Horse With A Unicorn Wish',
    subtitle: 'Sprinkle Sparkle dreams come true.',
    coverImage:
      '/images/Covers/Charley-The-Horse-With-A-Unicorn-Wish-Cover.jpg',
    fullCover:
      '/images/FullCovers/Charley-The-Horse-With-A-Unicorn-Wish-Full-Cover.jpg',
    numberOfPages: '27',
    readingAge: '3 - 10 years',
    dimensions: '8.5 x 0.07 x 8.5 inches',
    itemWeight: '3.68 ounces',
    language: 'English',
    bookPages: [
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-1.jpg',
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-2.jpg',
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-3.jpg',
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-4.jpg',
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-5.jpg',
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-6.jpg',
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-7.jpg',
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-8.jpg',
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-9.jpg',
      '/images/Charley-The-Horse-With-A-Unicorn-Wish/Charley-The-Horse-With-A-Unicorn-Wish-10.jpg',
    ],
    summary:
      '<div><p>In a meadow full of ordinary horses, Charley dreamed of something more—she wanted to be a unicorn!</p><p>Charley’s heart is set on proving she’s special. She practices her unicorn skills and performs acts of kindness, from sharing apples to helping lost animals. One day, a magical Sparkle Sprite appears and tells Charley that true unicorns need kindness, courage, and a touch of magic. Through her brave and kind deeds, Charley transforms into a unicorn with a shimmering horn and rainbow mane.</p><p>Join Charley on her magical journey as she discovers that being a unicorn is about sharing her unique gifts and spreading joy. Perfect for children ages 3-8, this enchanting tale will inspire young readers to believe in the magic of their dreams.</p><p>Get your copy today and let Charley inspire your little ones!</p><div>',
    isbn: '979-8329800135',
    date: 'June 29, 2024',
    tags: [],
    category: '',
    backgroundColor: '#C22E7C',
    backgroundMode: 'dark',
    textColor: '#fff',
    amazonLink:
      'https://www.amazon.com/Charley-Horse-Unicorn-Wish-Doodle/dp/B0D8G3D9KT/ref=sr_1_1?crid=1ZIIGSA43G1F7&dib=eyJ2IjoiMSJ9.CZrTS6BWSuESYe93PHWUwF4V9j7EoKXFJyXwWHZbolPu1_3NATpd6wNMHRTluwXX.IoyUoS0BpesM96IHd1p4Otg7orUQnFSo6P617YH8PUU&dib_tag=se&keywords=childens+books+unicorn+wish&qid=1719863443&s=digital-text&sprefix=charley+the+horse+with+a+unicorn+wish%2Cdigital-text%2C132&sr=1-1',
  },
  {
    id: 'the-whimsical-bakery-adventures-of-a-foodie-fox',
    title: 'The Whimsical Bakery: Adventures of a Foodie Fox',
    subtitle: "A Children's Book About Creativity, Friendship, and Community.",
    coverImage:
      '/images/Covers/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-Cover.jpg',
    fullCover:
      '/images/FullCovers/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-Full-Cover.jpg',
    numberOfPages: '39',
    readingAge: '3 - 10 years',
    dimensions: '8.5 x 0.07 x 8.5 inches',
    itemWeight: '3.68 ounces',
    language: 'English',
    bookPages: [
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-1.jpg',
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-2.jpg',
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-3.jpg',
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-4.jpg',
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-5.jpg',
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-6.jpg',
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-7.jpg',
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-8.jpg',
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-9.jpg',
      '/images/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox/The-Whimsical-Bakery-Adventures-Of-A-Foodie-Fox-10.jpg',
    ],
    summary:
      '<div><p>Step into the enchanting world of Felix the Fox and his magical bakery where every treat tells a tale! Join Felix as he whips up extraordinary creations that change flavors with every bite, from shimmering cakes to storytelling pies.</p><p>Perfect for young readers aged 3 to 8, this delightful tale of friendship and creativity will inspire imaginations and tickle taste buds!</p><div>',
    isbn: '979-8329910049',
    date: 'June 30, 2024',
    tags: [],
    category: '',
    backgroundColor: '#EBBA1B',
    textColor: '#000',
    backgroundMode: 'light',
    amazonLink:
      'https://www.amazon.com/dp/B0D8J29767/ref=sr_1_1?crid=21C4NUUMZL2IH&dib=eyJ2IjoiMSJ9.pBd1nVR85HSYft9tVdTYEAf_mQEIUUviurlHLpfQfg_Vz1HMO6e6RNUX80TVpac6Eo5XpWhN2Gwqt-eff_orjFdLh-dmqn-n_Qa3oeo3tzNUusj5i6q3epcYZm_AP-zs.bfBNZ1Dy7vgzvOjZTPRxnvO_vXDB101t2Bq3uoptlkw&dib_tag=se&keywords=children%27s+book+food+fox&qid=1719843797&s=books&sprefix=foodie+fox+childrens+book%2Cstripbooks%2C157&sr=1-1',
  },
  {
    id: 'speedy-one-fast-sloth',
    title: 'Speedy: One Fast Sloth',
    subtitle: '',
    coverImage: '/images/Covers/Speedy-One-Fast-Sloth-Cover.jpg',
    fullCover: '/images/FullCovers/Speedy-One-Fast-Sloth-Full-Cover.jpg',
    numberOfPages: '27',
    readingAge: '3 - 10 years',
    dimensions: '8.5 x 0.07 x 8.5 inches',
    itemWeight: '3.68 ounces',
    language: 'English',
    bookPages: [
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-1.jpg',
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-2.jpg',
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-3.jpg',
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-4.jpg',
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-5.jpg',
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-6.jpg',
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-7.jpg',
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-8.jpg',
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-9.jpg',
      '/images/Speedy-One-Fast-Sloth/Speedy-One-Fast-Sloth-10.jpg',
    ],
    summary:
      "<div><p>In a sunlit forest where sloths hang lazily, meet Speedy, a dreamer unlike any other. He's determined to become a race car driver, practicing tirelessly while facing doubts from his forest friends.</p><p>When Speedy gets a chance to race with a famous driver, he proves his courage by making a life-saving decision, becoming a hero and fulfilling his dream of becoming the fastest sloth ever.</p><p>Perfect for children 3 - 8 years old, who love stories of bravery and adventure. <b>Speedy: The fastest sloth in all of Sloth History</b> will inspire young readers to chase their dreams while knowing right from wrong.</p><div>",
    isbn: '979-8329750317',
    date: 'June 28, 2024',
    tags: [],
    category: '',
    backgroundColor: '#323E44',
    backgroundMode: 'dark',
    textColor: '#fff',
    amazonLink:
      'https://www.amazon.com/Speedy-One-Fast-Sloth-Resilience/dp/B0D8FL2P1Z/ref=sr_1_1?crid=2H4G5YNE9WNPW&dib=eyJ2IjoiMSJ9.TUM5d19kT2l6BvRBehnEsP9h_3lkvoojVSUl79f8-T_EtmNUKFl4XhQbPg8gFkeo.K681swwEhC_6qT4oMBgHJ8u_uJdkeOfKCUSmFmyb8YA&dib_tag=se&keywords=sloth+racing+car+children%27s+book&qid=1719961523&s=books&sprefix=speedy+one+fast+sloth+childrens+book%2Cstripbooks%2C127&sr=1-1',
  },
  {
    id: 'max-magical-burps',
    title: "Max's Magical Burps",
    subtitle: '',
    coverImage: '/images/Covers/Max-Magical-Burps-Cover.jpg',
    fullCover: '/images/FullCovers/Max-Magical-Burps-Full-Cover.jpg',
    numberOfPages: '30',
    readingAge: '3 - 10 years',
    dimensions: '8.5 x 0.07 x 8.5 inches',
    itemWeight: '3.68 ounces',
    language: 'English',
    bookPages: [
      '/images/Max-Magical-Burps/Max-Magical-Burps-1.jpg',
      '/images/Max-Magical-Burps/Max-Magical-Burps-2.jpg',
      '/images/Max-Magical-Burps/Max-Magical-Burps-3.jpg',
      '/images/Max-Magical-Burps/Max-Magical-Burps-4.jpg',
      '/images/Max-Magical-Burps/Max-Magical-Burps-5.jpg',
      '/images/Max-Magical-Burps/Max-Magical-Burps-6.jpg',
      '/images/Max-Magical-Burps/Max-Magical-Burps-7.jpg',
      '/images/Max-Magical-Burps/Max-Magical-Burps-8.jpg',
      '/images/Max-Magical-Burps/Max-Magical-Burps-9.jpg',
      '/images/Max-Magical-Burps/Max-Magical-Burps-10.jpg',
    ],
    summary:
      '<div><p>This captivating story follows Elara, a young Spartan girl known for her tears, as she embarks on a journey of self-discovery.</p><p>When faced with unexpected adversity, Elara uncovers her hidden courage and warrior spirit, inspiring young readers to embrace their own inner strength.</p><p>With vibrant language and engaging characters, this tale sparks imagination and empowers children aged 3-8 to confront their fears and stand up for what is right.<p>Perfect for bedtime reading or classroom discussions, this empowering story teaches valuable lessons about bravery, resilience, and the importance of believing in oneself.</p><div>',
    isbn: '979-8329910049',
    date: 'June 30, 2024',
    tags: [],
    category: '',
    backgroundColor: '#323E44',
    textColor: '#fff',
    backgroundMode: 'dark',
    amazonLink:
      'https://www.amazon.com/dp/B0D9GQMNQT/ref=sr_1_1?crid=21C4NUUMZL2IH&dib=eyJ2IjoiMSJ9.pBd1nVR85HSYft9tVdTYEAf_mQEIUUviurlHLpfQfg_Vz1HMO6e6RNUX80TVpac6Eo5XpWhN2Gwqt-eff_orjFdLh-dmqn-n_Qa3oeo3tzNUusj5i6q3epcYZm_AP-zs.bfBNZ1Dy7vgzvOjZTPRxnvO_vXDB101t2Bq3uoptlkw&dib_tag=se&keywords=children%27s+book&qid=1719843797&s=books&sprefix=burping+burps+childrens+book%2Cstripbooks%2C157&sr=1-1',
  },
  /* {
    id: 'sparta-and-the-bravest-little-girl',
    title: 'Sparta And The Bravest Little Girl',
    subtitle: '',
    coverImage: '/images/Covers/Sparta-And-The-Bravest-Little-Girl-Cover.jpg',
    fullCover:
      '/images/FullCovers/Sparta-And-The-Bravest-Little-Girl-Full-Cover.jpg',
    numberOfPages: '30',
    readingAge: '3 - 10 years',
    dimensions: '8.5 x 0.07 x 8.5 inches',
    itemWeight: '3.68 ounces',
    language: 'English',
    bookPages: [
      '/images/Sparta-And-The-Bravest-Little-Girl/Sparta-And-The-Bravest-Little-Girl-1.jpg',
      '/images/Sparta-And-The-Bravest-Little-Girl/Sparta-And-The-Bravest-Little-Girl-2.jpg',
      '/images/Sparta-And-The-Bravest-Little-Girl/Sparta-And-The-Bravest-Little-Girl-3.jpg',
      '/images/Sparta-And-The-Bravest-Little-Girl/Sparta-And-The-Bravest-Little-Girl-4.jpg',
      '/images/Sparta-And-The-Bravest-Little-Girl/Sparta-And-The-Bravest-Little-Girl-5.jpg',
      '/images/Sparta-And-The-Bravest-Little-Girl/Sparta-And-The-Bravest-Little-Girl-6.jpg',
      '/images/Sparta-And-The-Bravest-Little-Girl/Sparta-And-The-Bravest-Little-Girl-7.jpg',
      '/images/Sparta-And-The-Bravest-Little-Girl/Sparta-And-The-Bravest-Little-Girl-8.jpg',
    ],
    summary:
      '<div><p>This captivating story follows Elara, a young Spartan girl known for her tears, as she embarks on a journey of self-discovery.</p><p>When faced with unexpected adversity, Elara uncovers her hidden courage and warrior spirit, inspiring young readers to embrace their own inner strength.</p><p>With vibrant language and engaging characters, this tale sparks imagination and empowers children aged 3-8 to confront their fears and stand up for what is right.<p>Perfect for bedtime reading or classroom discussions, this empowering story teaches valuable lessons about bravery, resilience, and the importance of believing in oneself.</p><div>',
    isbn: '979-8329910049',
    date: 'June 30, 2024',
    tags: [],
    category: '',
    backgroundColor: '#323E44',
    textColor: '#fff',
    backgroundMode: 'dark',
    amazonLink:
      'https://www.amazon.com/dp/B0D8J29767/ref=sr_1_1?crid=21C4NUUMZL2IH&dib=eyJ2IjoiMSJ9.pBd1nVR85HSYft9tVdTYEAf_mQEIUUviurlHLpfQfg_Vz1HMO6e6RNUX80TVpac6Eo5XpWhN2Gwqt-eff_orjFdLh-dmqn-n_Qa3oeo3tzNUusj5i6q3epcYZm_AP-zs.bfBNZ1Dy7vgzvOjZTPRxnvO_vXDB101t2Bq3uoptlkw&dib_tag=se&keywords=children%27s+book&qid=1719843797&s=books&sprefix=foodie+fox+childrens+book%2Cstripbooks%2C157&sr=1-1',
  }, */
  {
    id: 'gilbert-goes-to-space',
    title: 'Gilbert Goes to Space',
    subtitle: 'The Giraffe Who Reached for the Stars',
    coverImage: '/images/Covers/Gilbert-Goes-To-Space-Cover.jpg',
    fullCover: '/images/FullCovers/Gilbert-Goes-To-Space-Full-Cover.jpg',
    numberOfPages: '27',
    readingAge: '3 - 10 years',
    dimensions: '8.5 x 0.07 x 8.5 inches',
    itemWeight: '3.68 ounces',
    language: 'English',
    bookPages: [
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-1.jpg',
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-2.jpg',
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-3.jpg',
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-4.jpg',
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-5.jpg',
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-6.jpg',
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-7.jpg',
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-8.jpg',
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-9.jpg',
      '/images/Gilbert-Goes-To-Space/Gilbert-Goes-To-Space-10.jpg',
    ],
    summary:
      "<div><p>Follow Gilbert's journey as he pursues his unlikely dream. Despite his friends' doubts, Gilbert's determination leads him to the Animal Astronaut Program. Through hard work and belief in himself, Gilbert proves that even the wildest dreams can come true!</p><p>Gilbert Goes to Space is a heartwarming story that encourages children to believe in their dreams. Perfect for children ages 3-8, this book will take young readers on a magical journey from the savanna to the stars.</p><div>",
    isbn: '979-8329521900',
    date: 'June 26, 2024',
    tags: [],
    category: '',
    backgroundColor: '#323E44',
    backgroundMode: 'dark',
    textColor: '#fff',
    amazonLink:
      'https://www.amazon.com/Gilbert-Goes-Space-Giraffe-Reached/dp/B0D84441PR/ref=sr_1_1?crid=1VR9SO8QAFWMI&dib=eyJ2IjoiMSJ9.UV-2Jwpe6BgjLiwNqtos6l2XXHIKt3oBAWXW7TKNdF_GjHj071QN20LucGBJIEps.80jvda4oPY-RZkoUwPOFXlL1qpIgeW-i3Q1KFTqbSxA&dib_tag=se&keywords=childens+book&qid=1719841134&s=books&sprefix=gilbert+goes+to+spac%2Cstripbooks%2C120&sr=1-1',
  },
  {
    id: 'the-foodie-fox-the-great-culinary-challenge',
    title: 'The Foodie Fox: The Great Culinary Challenge!',
    subtitle: '',
    coverImage:
      '/images/Covers/The-Foodie-Fox-The-Great-Culinary-Challenge-Cover.jpg',
    fullCover:
      '/images/FullCovers/The-Foodie-Fox-The-Great-Culinary-Challenge-Full-Cover.jpg',
    numberOfPages: '189',
    readingAge: '5 - 13 years',
    dimensions: '6 x 0.64 x 9 inches',
    itemWeight: '12.2 ounces',
    language: 'English',
    bookPages: [
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-1.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-2.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-3.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-4.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-5.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-6.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-7.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-8.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-9.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-10.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-11.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-12.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-13.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-14.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-15.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-16.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-17.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-18.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-19.jpg',
      '/images/The-Foodie-Fox-The-Great-Culinary-Challenge/The-Foodie-Fox-The-Great-Culinary-Challenge-20.jpg',
    ],
    summary:
      "<div><p>A Foodie Adventure Around the World! Get ready for a delicious adventure with Felix and Rosie, The Whimsical Bakery's star bakers!</p><p>They're on a mission to win the Great Culinary Challenge by exploring eight amazing countries, discovering magical ingredients, and learning about different cultures along the way.</p><p>With bravery and kindness, they'll face exciting challenges and create yummy treats that will make your taste buds happy!</p><p>Countries visited in this book:Italy, Greece, France, Norway, Mexico, India, China, Japan</p?><div>",
    isbn: '979-8333945518',
    date: 'Julu 22, 2024',
    tags: [],
    category: '',
    backgroundColor: '#23304B',
    backgroundMode: 'dark',
    textColor: '#fff',
    amazonLink:
      'https://www.amazon.com/dp/B0DBLF95S6/ref=sr_1_1?crid=B26SU57XLQLH&dib=eyJ2IjoiMSJ9.c3xS6uS0jPQJ_agS7YWhohJjMLBOYsGpuy02V6DhQTmja_h9BT8JCYSWSedgMvXn7CJi55Qmz1M9MtqkITVJY4iQnr3qkIF2HGcmvBRt5p4.r3UlBaZN8SDC7WBzS_Ug8yF5g3VH3CoLyw_EZamsuqk&dib_tag=se&keywords=children%27s+book+the+foodie+fox&qid=1722390731&sprefix=children%27s+book+the+foodie+fox%2Caps%2C220&sr=8-1',
  },
];
