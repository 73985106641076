import React from 'react';
import './Books.scss';
import { Link } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation';

function Books({ data }) {
  console.log('data', data);
  return (
    <div className="Books Page">
      <Navigation />

      <div className="list">
        {data?.map((d) => (
          <Link to={`/book/${d.id}`}>
            <div className="item">
              <img src={d.coverImage} alt={d.title} />
              <h3>{d.title}</h3>
            </div>
          </Link>
        ))}
      </div>
      <div className="copy-right">
        Text and illustrations copyright © Doodle Bug 2024. All rights reserved.
      </div>
    </div>
  );
}

export default Books;
