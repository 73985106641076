import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './Book.scss';

import Image from '../../components/Image/Image';
import Tags from '../../components/Tags/Tags';

function Book({ data }) {
  const params = useParams();
  const [book, setBook] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('params', params.id);
    if (!data) return;
    const p = data.find((d) => d.id === params.id);
    console.log('find', p);
    setBook(p);
    console.log('book', p);
  }, [params.id, data]);

  return (
    <div
      className="book"
      style={{ backgroundColor: book?.backgroundColor, color: book?.textColor }}
    >
      <header style={{ color: book?.textColor }}>
        <div className="actions">
          <button
            onClick={() => navigate('/')}
            style={{
              color: book?.textColor,
            }}
          >
            <img
              src="/icons/chevron-back.svg"
              alt="back"
              className="back"
              style={
                book?.backgroundMode === 'dark' ? { filter: 'invert(1)' } : {}
              }
            />
            Back to Catalog
          </button>
        </div>
        {/* <div onClick={() => navigate('/')}>
          <img src="/icons/chevron-back.svg" alt="back" />
          Back to Catalog
        </div> */}
        <div className="actions">
          <button
            onClick={() => window.open(book?.amazonLink)}
            style={{ color: book?.textColor }}
          >
            <img
              className="amazon-logo"
              src="/logos/amazon-logo.svg"
              style={
                book?.backgroundMode === 'dark' ? { filter: 'invert(1)' } : {}
              }
              alt="Buy on Amazon"
            />
            Buy on Amazon
          </button>
          {/* <button>
            Share with a Friend
            <img src="/icons/lock-open.svg" alt="Unlock Article" />
          </button> */}
        </div>
      </header>

      <div className="item">
        <Image
          src={book?.fullCover}
          alt={book?.title}
          className="cover-background"
          title={book?.title}
          height="100%"
          width="100%"
        />
        <div className="book-images">
          {book?.bookPages &&
            book.bookPages.map((b, index) => (
              <Image
                src={b}
                alt={`${book?.title} Page ${index + 1}`}
                className="bookPage"
                title={`${book?.title} Page ${index + 1}`}
                height="auto"
                width="400px"
              />
            ))}
        </div>

        {/* {book?.tags && (
          <Tags
            tags={book?.tags}
            createdAt={book?.createdAt}
            reputation={book?.reputation}
          />
        )} */}
        <h4>{book?.preview?.title}</h4>
      </div>
      <div
        className="summary"
        dangerouslySetInnerHTML={{ __html: book?.summary }}
      ></div>
      <div className="copy-right">
        Text and illustrations copyright © Doodle Bug 2024. All rights reserved.
      </div>
    </div>
  );
}

export default Book;
