import React, { useState } from 'react';
import './Image.scss';

function Image({
  src,
  title,
  className,
  height = '100%',
  width = '100%',
  backgroundColor = 'transparent',
  objectFit = 'cover',
}) {
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [image, setImage] = useState(src);

  const imageOnLoad = (event) => {
    /* if (event.currentTarget.className !== 'error') {
      event.currentTarget.className = 'success';
    } */
    //setIsLoaded(true);
  };
  const imageOnError = (event) => {
    /* event.currentTarget.src = '/icons/navigate.svg';
    event.currentTarget.className = 'hidden';
    setIsError(true); */
  };

  return (
    <div className="image-component fade-in">
      <img
        style={{ height: height, width: width, objectFit: objectFit }}
        className={className}
        src={src}
        onLoad={imageOnLoad}
        onError={imageOnError}
        /* width={width} */
        alt={title}
      />
      {isError && <h4>{title}</h4>}
    </div>
  );
}

export default Image;
