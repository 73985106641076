import React, { useState } from 'react';
import './Join.scss';
import Navigation from '../../components/Navigation/Navigation';

function Join() {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [showToast, setShowToast] = useState(false);

  const signUpUser = () => {
    setIsSending(true);
    setIsEnabled(false);
    document.querySelector('#submitForm').click((e) => e.preventDefault());
    setTimeout(() => {
      setIsSending(false);
      setIsEnabled(false);
      setName('');
      setMobile('');
      setEmail('');
      setShowToast(true);
    }, 1000);

    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  };

  return (
    <div className="About Page Join">
      <Navigation />
      <div className="content">
        <h1>Join Our List</h1>
        Don't miss out on the fun! Subscribe to our emails and discover what
        surprises await you in your inbox. We promise to keep things
        interesting.
        <div className="form">
          <div className="form">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email..."
              />
            </div>
            <div className="form-group">
              <label>Full Name</label>
              <input
                type="email"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Full Name..."
              />
            </div>
            <div className="form-group">
              <label>Mobile</label>
              <input
                type="number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Mobile Number..."
              />
            </div>
          </div>

          <button onClick={() => signUpUser()}>
            {isSending ? 'Saving...' : 'Join Email List'}
          </button>
          <br />
          <div>{showToast && 'Successfully Joined Email List!'}</div>
        </div>
      </div>
      <iframe
        title="secret-frame-one"
        name="secret-frame-one"
        width="0"
        height="0"
        border="0"
        style={{ display: 'none' }}
      ></iframe>
      <form
        className="form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSc862TwxW5nMjXsDfQ_bbuiHzVUpT7h9os82Ho52sgAlLTGsQ/formResponse"
        id="ss-form1"
        method="POST"
        target="secret-frame-one"
        style={{ display: 'none' }}
      >
        <div>
          <input name="entry.659286338" readOnly type="email" value={email} />
          <input name="entry.713497301" readOnly value={name} />
          <input name="entry.307039877" readOnly value={mobile} />
        </div>

        <input id="submitForm" type="submit" value="Submit Subscribe" />
      </form>
    </div>
  );
}

export default Join;
