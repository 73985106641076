import React, { useEffect, useState } from 'react';
import './App.scss';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { types, tags, tabs, currentUser } from './config';
import { isMobileSafari, browserName } from 'react-device-detect';
import Search from './pages/Search/Search';
import Book from './pages/Book/Book';
import Books from './pages/Books/Books';
import { data } from './data';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import Join from './pages/Join/Join';

function App() {
  const [showTabbedNav, setShowTabbedNav] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('browser', browserName, isMobileSafari);
  }, [browserName]);

  useEffect(() => {
    if (location.pathname.includes('/post/')) {
      setShowTabbedNav(false);
    } else {
      setShowTabbedNav(true);
    }
  }, [location]);

  return (
    <div className="App">
      <div
        className={`content ${isMobileSafari ? 'ios' : ''} ${
          showTabbedNav ? '' : 'full-height'
        }`}
      >
        <Routes>
          <Route path="/search" exact element={<Search data={data} />} />
          <Route path="/" exact element={<Books data={data} />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/join" exact element={<Join />} />
          <Route path="/book/:id" exact element={<Book data={data} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      {/* {showTabbedNav && (
        <div className="tabbed-nav">
          {tabs.map((t) => (
            <div
              onClick={() => navigate(`${t.path}`)}
              className={`tab ${location.pathname === t.path ? 'active' : ''}`}
            >
              <img src={t.icon} alt={t.title} />
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
}

export default App;
