import React from 'react';
import '../About/About.scss';
import { Link } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation';

function Contact() {
  return (
    <div className="About Page Contact">
      <Navigation />
      <div className="content">
        <h1>Contact Us</h1>
        <p>
          Welcome to The Doodle Bug, your go-to destination for delightful
          children's books available on Amazon. We value your feedback,
          inquiries, and suggestions. Please feel free to reach out to us using
          the following options:
        </p>
        <h4>Customer Service</h4>
        <p>
          For assistance with orders, product information, or any other
          inquiries related to your purchase, please reach out to us on
          instagram at{' '}
          <a href="https://www.instagram.com/doodlebugbooks/">doodlebugbooks</a>
          .
        </p>
        <h4>General Inquiries:</h4>
        <p>
          For general questions, feedback about our website, or partnerships,
          please reach out to us on instagram at{' '}
          <a href="https://www.instagram.com/doodlebugbooks/">doodlebugbooks</a>
          .
        </p>
        <h4>Social Media:</h4>
        <p>
          Stay connected with us through our social media channels: instagram:
          <a href="https://www.instagram.com/doodlebugbooks/">doodlebugbooks</a>
          .
        </p>
        <p>
          Thank you for choosing The Doodle Bug for your children's book needs.
          We look forward to serving you!
        </p>
      </div>
    </div>
  );
}

export default Contact;
