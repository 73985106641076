import React from 'react';
import { Link } from 'react-router-dom';

const navItems = [
  { id: 0, title: 'Books', path: '/books' },
  { id: 1, title: 'About', path: '/about' },
  { id: 2, title: 'Contact', path: '/contact' },
  { id: 3, title: 'Join', path: '/join' },
];

function Navigation() {
  return (
    <div className="nav-header">
      <Link to="/">
        <img
          src="/logos/DoodleBug.png"
          alt="Doodle Bug Logo"
          className="logo"
        />
      </Link>
      {navItems.map((n) => (
        <Link to={n.path}>{n.title}</Link>
      ))}
    </div>
  );
}

export default Navigation;
