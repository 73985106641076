import React, { StrictMode } from 'react';
import ReactDOM, { createRoot, hydrateRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';

/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
 */

const rootElement = document.getElementById('root');

// Check if content exists before deciding on render vs. hydrate
if (rootElement.hasChildNodes()) {
  const root = hydrateRoot(
    rootElement,
    <StrictMode>
      <Router>
        <App />
      </Router>
    </StrictMode>
  );

  // Optional but recommended: Unmount on hot module replacement
  if (module.hot) {
    module.hot.accept();
  }
} else {
  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <Router>
        <App />
      </Router>
    </StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
